const vehicleTypes = {
  '1': 'Car',
  '2': 'Mid-sized',
  '3': 'Cargo Van',
  '4': 'Pickup Truck',
  '5': 'Box Truck'
};

// Consider these non-human-readable values deprecated,
// because they do not match the codes used on the backend.
// Use the `getVehicleCodeFromId` util instead if possible
const vehicleTypeSlugs = {
  '1': 'car',
  '2': 'midsized',
  '3': 'cargo',
  '4': 'pickup',
  '5': 'boxTruck'
};

const getVehicleTypeFromId = (id, humanReadable = true) => {
  const vehicle = humanReadable ? vehicleTypes[id] : vehicleTypeSlugs[id];
  if (typeof vehicle !== 'undefined') return vehicle;
  else return id;
};

export default getVehicleTypeFromId;

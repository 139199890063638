import upperFirst from 'lodash/upperFirst';

// Replaces underscores with spaces,
// removes leading and trailing underscores,
// and capitalizes the first letter of the string.
export const humanize = str => {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function(m) {
      return m.toUpperCase();
    });
};

export const titleCase = str =>
  str
    .split(' ')
    .map(str => upperFirst(str.toLowerCase()))
    .join(' ');

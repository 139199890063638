export default function scrollElementIntoViewSmoothly(el, block = 'center') {
  try {
    el.scrollIntoView({
      behavior: 'smooth',
      block: block
    });
  } catch (e) {
    try {
      // FF 52 doesn't support `block: 'center'` and throws an error, so try 'start' instead to keep smooth scrolling
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    } catch (e) {
      // Fall back to simple jump behavior for any other browsers
      el.scrollIntoView(false);
    }
  }
}

const formatTimeZone = timeZone => {
  const timeZoneMapping = {
    'America/Chicago': 'Central Time',
    'America/Chihuahua': 'Chihuahua',
    'America/Denver': 'Mountain Time',
    'America/Halifax': 'Atlantic Time',
    'America/Indiana/Indianapolis': 'Indiana (East) Time',
    'America/Juneau': 'Alaska Time',
    'America/Los_Angeles': 'Pacific Time',
    'America/Mazatlan': 'Mazatlan',
    'America/Mexico_City': 'Mexico City',
    'America/Monterrey': 'Monterrey',
    'America/New_York': 'Eastern Time',
    'America/Phoenix': 'Arizona Time',
    'America/Regina': 'Saskatchewan Time',
    'America/St_Johns': 'Newfoundland',
    'America/Tijuana': 'Tijuana'
  };

  const tz = timeZoneMapping[timeZone];

  if (tz) {
    return tz;
  } else {
    return timeZone;
  }
};

export default formatTimeZone;

import { format, isYesterday, isToday, isTomorrow } from 'date-fns';

const formattedDate = (dateObj, options) => {
  if (options?.friendlyDayName) {
    if (isYesterday(dateObj)) return 'yesterday';
    if (isTomorrow(dateObj)) return 'tomorrow';
    if (isToday(dateObj)) {
      if (options.displayToday) return 'today';
      else return '';
    }
  }
  return format(dateObj, 'M/d/y');
};

export default formattedDate;

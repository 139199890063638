import { Analytics } from '@dispatch/analytics';
import segmentPlugin from '@analytics/segment';

const segmentWriteKey = window.segmentWriteKey;

const plugins = [
  ...(segmentWriteKey
    ? segmentPlugin({
        writeKey: segmentWriteKey
      })
    : [])
];

const analytics = Analytics({
  app: 'Dispatch',
  plugins
});

// This boolean is meant for use in other files to determine if analytics is enabled,
// since we will no longer be able to rely on window.analytics presence
const isEnabled = Boolean(window.analytics) || plugins.length > 0;

const track = (event, properties) => {
  if (window.LogRocket) {
    window.LogRocket.track(event, properties);
  }

  if (window.analytics) {
    // remove this condition when removing the :abstract_analytics feature flag, leaving only the else block contents
    window.analytics.track(event, properties);
  } else {
    analytics.track(event, properties);
  }
};

const identify = (userId, traits) => {
  analytics.identify(userId, traits);
};

export { track, identify, isEnabled };

import tailwindConfig from '../../../../tailwind.config';

export default function(status) {
  const statuses = {
    estimate: tailwindConfig.theme.extend.colors.gray['500'],
    claimed: tailwindConfig.theme.extend.colors.gray['500'],
    scheduled: tailwindConfig.theme.extend.colors.gray['500'],
    unassigned: tailwindConfig.theme.extend.colors.gray['500'],
    driver_assigned: tailwindConfig.theme.extend.colors.blue['500'],
    en_route_to_pickup: tailwindConfig.theme.extend.colors.blue['500'],
    at_pickup: tailwindConfig.theme.extend.colors.blue['500'],
    out_for_delivery: tailwindConfig.theme.extend.colors.blue['500'],
    en_route_to_drop_off: tailwindConfig.theme.extend.colors.blue['500'],
    at_drop_off: tailwindConfig.theme.extend.colors.blue['500'],
    delivered: tailwindConfig.theme.extend.colors.green['800'],
    cancelled: tailwindConfig.theme.extend.colors.red['500'],
    editing: tailwindConfig.theme.extend.colors.gray['500']
  };
  return statuses?.[status] ?? '';
}

export default {
  email: /[^@\s]+@[^.\s]\S+/,
  phone: /^[2-9]\d{2}-\d{3}-\d{4}$/,
  sms: /^(1?([2-9]\d{9})|((\+0?1\s)?\(?[2-9]\d{2}\)?[\s.-]\d{3}[\s.-]\d{4}))$/,
  password: /^(?=.*?[#?!@$%^&*-])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?[0-9]).{8,}$/,
  website: /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&//=]*)$/
};

// EXAMPLES

// sms should match these:

// 2342342345
// 12342342345
// 12111111111
// 16122022112
// 234-234-2345
// 211-111-1111
// (612) 212-2345
// (612) 212.2345
// (612) 212 2345
// +1 234 234 2345
// +1 (612) 212-2345
// +1 (612) 212.2345
// +1 (612) 212 2345

// sms should NOT match these:

// 1234567890         // only 10 numbers and first number is a 1
// 02342342345        // starts with a 0
// 10342342345        // area code starts with a 0
// 11342342345        // area code starts with a 1
// 123-123-1234       // area code starts with a 1
// (123) 123 1234     // area code starts with a 1

export default function storedOrderFormHasEntries(storedState) {
  // Exit early if there is no stored state
  if (!storedState || typeof storedState !== 'object') {
    return false;
  }

  const {
    branch,
    branchId,
    deliveries,
    dropOffTime,
    jobName,
    organization,
    organizationId,
    pickupDate,
    pickupTime,
    trackingEmail,
    trackingSms,
    vehicleType,
    requestedVehicleSpecs,
    requestedVehicleEquipment
  } = storedState;

  if (
    branch ||
    branchId ||
    dropOffTime ||
    jobName ||
    organization ||
    organizationId ||
    pickupDate ||
    pickupTime ||
    trackingEmail ||
    trackingSms ||
    vehicleType ||
    requestedVehicleEquipment ||
    requestedVehicleSpecs
  ) {
    // Return true if any one of these fields is truthy
    return true;
  } else {
    // If none of those have truthy values, check if a pickup or drop off is entered.
    // This is done in a separate step because it's a more expensive operation
    // and easier to reason about if kept out of the initial `if` statement.
    const pickup = deliveries?.[0]?.pickup;
    const dropOff = deliveries?.[0]?.dropOff;
    if (typeof pickup === 'object' && Object.keys(pickup).length) return true;
    if (typeof dropOff === 'object' && Object.keys(dropOff).length) return true;
  }

  // return false if no conditions are met
  return false;
}

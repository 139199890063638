// Expects an address object formatted like google's API
const formattedAddressSingleLine = address => {
  if (!address) return '';
  return `${address.name ? `${address.name}, ` : ''}${address.street ?? ''}, ${
    address.secondary_address?.trim()
      ? `#${address.secondary_address.trim()}, `
      : ''
  }${address.city ?? ''}, ${address.state ?? ''} ${address.postal_code ?? ''}`;
};

export default formattedAddressSingleLine;

const kmConversionFactor = 0.621371;

const kilometersToMiles = kilometers => {
  return kilometers * kmConversionFactor;
};

const milesToKilometers = miles => {
  return miles / kmConversionFactor;
};

export { kilometersToMiles, milesToKilometers };

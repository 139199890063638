const vehicleIds = {
  car: 1,
  pickup_truck: 2,
  van: 3,
  pickup_truck2: 4,
  box_truck: 5
};

const getVehicleIdFromCode = (code, convertToString) => {
  const id = vehicleIds[code];

  if (typeof id === 'undefined' || id === null) {
    return id;
  } else return convertToString ? id?.toString() : id;
};

export default getVehicleIdFromCode;

import React from 'react';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';

export default function(text, search) {
  const chunks = parse(text, match(text, search));

  return chunks.map((chunk, index) => {
    if (chunk.highlight) {
      return <strong key={`${index}`}>{chunk.text}</strong>;
    }

    return <span key={`${index}`}>{chunk.text}</span>;
  });
}

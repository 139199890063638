export default function(address, withNear) {
  let lat = address.geo_lat;
  let lng = address.geo_lng;

  if (lat) {
    lat = parseFloat(address.geo_lat).toFixed(6);
  }

  if (lng) {
    lng = parseFloat(address.geo_lng).toFixed(6);
  }

  if (!lat && !lng) return '';

  return `${lat}, ${lng} ${withNear ? 'near ' : ''}`;
}

export default function(status) {
  const statuses = {
    estimate: 'Estimate',
    claimed: 'Claimed',
    scheduled: 'Scheduled',
    unassigned: 'Assigning',
    driver_assigned: 'Driver assigned',
    // One day we will actually use this, after the statuses are refactored.
    // See the note here https://github.com/dispatchitinc/ms-monolith/blob/129974150baa637081a579ab9f075b32e64bbd8b/config/locales/en.yml#L329-L347
    en_route_to_pickup: 'Driver assigned',
    at_pickup: 'At pickup',
    out_for_delivery: 'Out for delivery',
    en_route_to_drop_off: 'Out for delivery',
    at_drop_off: 'At drop-off',
    delivered: 'Delivered',
    cancelled: 'Canceled',
    editing: 'Editing'
  };
  return statuses?.[status] ?? status;
}

const serviceTypes = {
  asap: 'ASAP',
  expedited: 'Expedited',
  standard: 'Standard',
  same_day: 'Same-day',
  multi_stop: 'Multi-stop',
  hotshot: 'Hotshot'
};

const getServiceTypeName = id => {
  // just return the input id if it's not in the serviceTypes object
  return serviceTypes[id] ?? id;
};

export default getServiceTypeName;

import { humanize } from './';

const vehicleTypes = {
  car: 'Car',
  pickup_truck: 'Mid-sized',
  pickup_truck2: 'Pickup Truck',
  van: 'Cargo Van',
  box_truck: 'Box Truck'
};

const getVehicleTypeFromCode = id => {
  const vehicle = vehicleTypes[id];
  if (typeof vehicle !== 'undefined') return vehicle;
  else return humanize(id.toString());
};

export default getVehicleTypeFromCode;

const vehicleTypeCodes = {
  '1': 'car',
  '2': 'pickup_truck', // midsize
  '3': 'van',
  '4': 'pickup_truck2', // pickup truck
  '5': 'box_truck'
};

const getVehicleCodeFromId = id => {
  const vehicle = vehicleTypeCodes[id];
  if (typeof vehicle !== 'undefined') return vehicle;
  else return id;
};

export default getVehicleCodeFromId;

// Translates the new service type names to the old service type names for visual display

const SERVICE_TYPE_TRANSLATIONS = {
  hotshot: 'hotshot',
  rush: 'hotshot',
  expedited: 'asap',
  priority: 'expedited',
  standard: 'standard',
  economy: 'same_day'
};

const translateServiceName = key => {
  return SERVICE_TYPE_TRANSLATIONS[key.toLowerCase()];
};

export default translateServiceName;

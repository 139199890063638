const getDefaultCheckedItem = (inputs, defaultValue) => {
  // if the field has a default value, set the active button to it
  if (defaultValue) {
    const selectedInput = inputs.find(input => {
      if (input.value === defaultValue) {
        return input.id;
      }
    });

    if (selectedInput) return selectedInput.id;
  }

  // iterates from the end of the array to mimic HTML's behavior
  // if multiple radio buttons have the 'checked' attribute
  for (let i = inputs.length - 1; i >= 0; i--) {
    if (inputs[i].checked === true) {
      return inputs[i].id;
    }
  }

  return null;
};

export default getDefaultCheckedItem;
